import { ref } from 'vue';

const echoServerConnection = ref(null);
const isConnectedEchoServer = ref(false);

export function useEchoServer(cbConnected, cbDisconnected, cdReconnecting) {
    if (isConnectedEchoServer.value === false) {
        echoServerConnection.value = window.Echo = new LaravelEcho({
            broadcaster: 'pusher',
            id: import.meta.env.VITE_PUSHER_APP_ID,
            key: import.meta.env.VITE_PUSHER_APP_KEY,
            secret: import.meta.env.VITE_PUSHER_APP_SECRET,
            host: import.meta.env.VITE_PUSHER_HOST,
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
            enabledTransports: ['ws', 'wss'],
            wsHost: import.meta.env.VITE_PUSHER_HOST,
            wsPort: import.meta.env.VITE_PUSHER_PORT,
            wssPort: import.meta.env.VITE_PUSHER_PORT,
            httpHost: import.meta.env.VITE_PUSHER_HOST,
            httpPort: import.meta.env.VITE_PUSHER_PORT,
            httpsPort: import.meta.env.VITE_PUSHER_PORT,
            userAuthentication: {
                endpoint: import.meta.env.VITE_PUSHER_SCHEME + "://" + import.meta.env.VITE_PUSHER_HOST + "/" + import.meta.env.VITE_PUSHER_AUTH_PATH,
                transport: "ajax",
                params: {},
                headers: {},
                paramsProvider: null,
                headersProvider: null,
                customHandler: null,
            },
        });
        echoServerConnection.value.connector.pusher.connection.bind('connected', () => {
            console.log('connected', echoServerConnection.value.socketId());

            if (typeof cbConnected === 'function') {
                cbConnected(!isConnectedEchoServer.value);
            }

            isConnectedEchoServer.value = true;
        });
        echoServerConnection.value.connector.pusher.connection.bind('disconnected', () => {
            console.log('disconnected');

            isConnectedEchoServer.value = false;

            if (typeof cbDisconnected === 'function') {
                cbDisconnected();
            }
        });
        echoServerConnection.value.connector.pusher.connection.bind('reconnecting', (attemptNumber) => {
            console.log('reconnecting', attemptNumber);

            if (typeof cdReconnecting === 'function') {
                cdReconnecting(attemptNumber);
            }
        });
    } else {
        if (typeof cbConnected === 'function') {
            cbConnected(echoServerConnection.value.socketId());
        }
    }


    return {
        echoServerConnection,
        isConnectedEchoServer,
    };
}
